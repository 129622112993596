import * as React from "react";
import { Helmet } from "react-helmet";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Header from "../components/Header";
import Head from "../components/Head";
import Footer from "../components/Footer";

import "../styles/index.scss";
import "../styles/privacy.scss";

import { Cookies } from "react-cookie-consent";

const Privacy = () => {
  const deleteCookies = () => {
    Cookies.remove("_ga");
    Cookies.remove("_ga_N9KJPC5PXY");
    Cookies.remove("CookieConsent");
    window.open("/", "_self");
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Huet & Haie | Politique de confidentialité</title>
        <meta
          name="description"
          content="Huet & Haie, situé à Vair-sur-Loire, près d’Ancenis, vous accompagne dans vos travaux d’aménagements et de restructuration de vos extérieurs, pour tous types de maisons individuelles ou d’habitats collectifs."
        />
        <link rel="canonical" href="https://huetethaie.fr/" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Head />
      <Header />
      <main id="main" className="site-main" role="main">
        <section id="privacy" className="privacy">
          <header>
            <div className="h1">Politique de confidentialité</div>
          </header>
          <div className="container privacy__container">
            <p>
              L'Utilisateur se déclare informé que la politique de
              confidentialité du Site doit être considérée comme lui apportant
              une information complémentaire mais indissociable des{" "}
              <AnchorLink to="/mentions-legales/#legal">
                mentions légales/CGU accessibles en suivant ce lien
              </AnchorLink>
              .
            </p>
            <div className="h2">Traitement des Données personnelles</div>
            <p>
              Les Données Personnelles de l'Utilisateur (notamment fournisseur
              d’accès, adresse IP, URL des liens, coordonnées etc...) peuvent
              faire l'objet d'un traitement par l'Editeur (et/ou tout
              Prestataire habilité) à des fins techniques (gestion et
              utilisation du Site notamment hébergement, délivrance de certains
              services etc..), commerciales ou marketing (relations avec les
              clients et prospects de l'Editeur) fondées sur l'intérêt légitime
              de ce dernier à promouvoir, maintenir et développer son activité
              professionnelle par l'intermédiaire du Site.
            </p>
            <p>
              Tout traitement de Données Personnelles d'un Utilisateur, qu'il
              soit effectué directement ou non par l'Editeur, est effectué en
              conformité, dans la mesure du possible, avec les dernières
              règlementations en vigueur (notamment Loi relative à
              l'informatique, aux fichiers et aux libertés, recommandations
              CNIL, Loi pour la confiance dans l'économie numérique, Règlement
              européen sur la protection des données - RGPD).
            </p>
            <p>
              L'Editeur ne saurait toutefois être tenue responsable de la
              défaillance d'un Prestataire qui ne se conformerait pas aux
              dernières réglementations en vigueur et/ou qui n'aurait pas (ou
              partiellement) respecter les prescriptions de l'Editeur, en
              matière de traitement de Données Personnelles.
            </p>
            <p>
              L'Utilisateur communique ses Données Personnelles en toute
              connaissance de cause, de manière volontaire notamment lorsqu’il
              procède par lui-même à leur saisie (l'Editeur se réservant le
              droit de considérer comme essentielles, donc obligatoires,
              certaines Données Personnelles de l'Utilisateur pour accéder au
              Site et/ou aux services qui y sont associés et/ou répondre à une
              ou plusieurs demandes particulières de l'Utilisateur).
            </p>
            <p>
              L'Editeur ne collecte et ne traite (directement ou par
              l'intermédiaire d'un ou plusieurs Prestataires) les Données
              Personnelles de l'Utilisateur que pour les besoins de son activité
              professionnelle (fins commerciales et suivi d'audience inclus en
              tant que besoin).
            </p>
            <p>
              L'Utilisateur est informé que ses Données Personnelles sont
              susceptibles d'être transférées à un Tiers (ou son conseil,
              avocat, notaire ou expert-comptable) uniquement dans l'hypothèse
              où celui-ci entendrait se porter acquéreur de tout ou parties des
              Titres de l'Editeur.
            </p>

            <div className="h2">Conservation des Données personnelles</div>

            <p>
              La durée maximale de conservation de tout ou partie des Données
              Personnelles de l'Utilisateur est de :
            </p>

            <ul>
              <li>
                En cas de conclusion d’un contrat ou d’une prestation de service
                :<br />
                - (i) période contractuelle,
                <br />- (ii) archivage intermédiaire pour une durée
                correspondante à la période de garantie éventuelle à laquelle
                s’ajoute la durée de prescription légale applicable.
              </li>
              <li>
                En l'absence de conclusion d’un contrat ou d’une prestation de
                service :<br />- (iii) 3 ans à compter de leur collecte en
                l’absence de conclusion d’un contrat ou de fourniture d'une
                prestation par l’Editeur ;
              </li>
              <li>
                Pour une durée de 13 mois à compter de leur
                implantation/création s’agissant des cookies.
              </li>
            </ul>

            <div className="h2">Droits de l’Utilisateur</div>

            <p>
              Dans le respect de la réglementation actuellement en vigueur,
              l’Utilisateur dispose, pour ses Données Personnelles, d’un droit
              d’accès, de rectification, de portabilité des informations, de
              limitation, d’effacement et d’opposition (pour des motifs
              légitimes).
            </p>
            <p>
              L'Utilisateur peut communiquer à l'Editeur des directives sur le
              traitement de ses Données Personnelles (communication, effacement,
              conservation etc...) de son vivant ou en cas de décès, sans
              préjudice d'un éventuel recours auprès de la Commission Nationale
              de l’Informatique et des Libertés (CNIL) en cas de violation de la
              réglementation applicable en pareille matière.
            </p>
            <p>
              Il est rappelé à l'Utilisateur que toute demande visant à
              s'opposer ou à limiter le traitement des Données Personnelles le
              concernant peut avoir une incidence défavorable voire empêcher la
              prise en compte d'une de ses demandes.
            </p>
            <p>
              Il en sera de même lorsque l'Utilisateur renseigne sciemment des
              Données Personnelles erronées.
            </p>
            <p>
              Pour toute réclamation, demande relatif à ses droits,
              l'Utilisateur est invité à effectuer une demande circonstanciée
              écrite et signée, accompagnée d’une copie de son titre d’identité
              avec sa signature en mentionnant l’adresse postale à laquelle la
              réponse doit être envoyée.
            </p>

            <div className="h2">Cookies – présentation générale</div>

            <p>
              Un cookie est, selon la définition proposée par la CNIL : "(..)un
              petit fichier stocké par un serveur dans le terminal (ordinateur,
              téléphone, etc.) [de l'Utilisateur] et associé à un domaine web
              (c’est à dire dans la majorité des cas à l’ensemble des pages d’un
              même site web). Ce fichier est automatiquement renvoyé lors de
              contacts ultérieurs avec le même domaine."
            </p>
            <p>
              Les cookies permettent à son émetteur de reconnaître le terminal
              de l'Utilisateur auprès de qui il est enregistré et de collecter
              des informations relatives à sa navigation internet.
            </p>
            <p>
              Les cookies qui peuvent, le cas-échéant, être émis sur ce Site :
            </p>
            <p>
              <strong>
                Cookies qui ne requiert pas le consentement préalable de
                l'Utilisateur
              </strong>
            </p>
            <ul>
              <li>
                Les cookies "nécessaires" permettent d’enregistrer des
                informations de l'Utilisateur entre deux consultations du Site
                sur un même appareil. Ils permettent notamment d'enregistrer des
                identifiants de connexion ou encore des éléments de
                personnalisation de l’interface. Ils ne requièrent pas de
                consentement de la part de l'Utilisateur.
              </li>
              <li>
                Les cookies "statistiques" permettent de suivre les actions de
                l'Utilisateur sur le Site. Lorsque les statistiques sont
                anonymes (c’est-à-dire ne permettent pas de retrouver une
                personne), le consentement de l'Utilisateur n’est pas
                nécessaire.
              </li>
            </ul>
            <p>
              <strong>
                Cookies qui requiert le consentement préalable de l'Utilisateur
              </strong>
            </p>
            <ul>
              <li>
                Les cookies "internes" ou "first-party" sont déposés par le
                Site. Ils peuvent être déposés en plus des cookies "nécessaires"
                et peuvent être utilisés pour collecter des données
                personnelles, suivre le comportement de l’utilisateur et servir
                à des finalités publicitaires.
              </li>
              <li>
                Les cookies dits "tiers", "tierce partie" ou "third party" sont
                des cookies déposés par (ou pour) un site B (souvent une régie
                publicitaire) sur un site A : cela permet au site B de voir
                quelles pages ont été visitées sur le site A par l'Utilisateur
                et de collecter des informations sur lui.
              </li>
            </ul>
            <p>
              L'Utilisateur peut contrôler et gérer l’installation de cookies en
              paramétrant son logiciel de navigation (de manière à accepter ou
              rejeter tous les cookies, voire être automatiquement informé
              lorsqu’un cookie est enregistré sur son terminal)
            </p>
            <p>
              Afin de procéder au paramétrage de son navigateur l'Utilisateur
              est invité à se référer aux liens ci- dessous (pour les
              navigateurs les plus courants) :
            </p>
            <p>
              <strong>Paramétrage de votre navigateur</strong>
            </p>
            <div className="container">
              <a
                className="button"
                href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en"
              >
                Chrome
              </a>
              &nbsp;
              <a
                className="button"
                href="https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-Cookies"
              >
                Internet Explorer
              </a>
              &nbsp;
              <a
                className="button"
                href="https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent?redirectlocale=fr&redirectslug=G%C3%A9rer+les+cookies"
              >
                FireFox
              </a>
              &nbsp;
              <a
                className="button"
                href=" http://help.opera.com/Windows/10.20/fr/cookies.html"
              >
                Opéra
              </a>
            </div>
            <p>
              <strong>
                Les liens Opt-out pour les cookies de mesure d’audience
                (statistiques)
              </strong>
            </p>
            <p>
              Pour les cookies déposés par Google Analytics :{" "}
              <a
                href="https://support.google.com/analytics/answer/181881"
                rel="noopener noreferrer"
              >
                support.google.com/analytics/answer/181881
              </a>
            </p>
            <p>
              <strong>Les cookies déposés sont :</strong>
            </p>
            <ul>
              <li>
                CookieConsent (obligatoire) : stocke le consentement de
                l'utilisateur
              </li>
              <li>
                _ga (facultatif) : déposé par Google pour compter le nombre de
                visites (conservé pendant 2 ans)
              </li>
              <li>
                _ga_* (facultatif) : déposé par Google pour compter le nombre de
                visites (conservé pendant 1 an)
              </li>
            </ul>
            <button className="button" onClick={deleteCookies}>
              Revoir mon consentement
            </button>
            <p>
              Pour plus d’information sur les cookies, vous pouvez vous rendre
              sur le site de la CNIL, à cette adresse :{" "}
              <a
                href="https://www.cnil.fr/vos-droits/vos-traces/les-cookies/"
                rel="noopener noreferrer"
              >
                cnil.fr/vos-droits/vos-traces/les-cookies/
              </a>
            </p>
            <p>L'Utilisateur est averti :</p>
            <ul>
              <li>
                qu'il peut également consulter le menu "aide" de son navigateur
                afin de connaître les modalités de paramétrage afférentes aux
                cookies ;
              </li>
              <li>
                que tout modification du paramétrage d'origine du navigateur est
                susceptible d'avoir une incidence sur sa navigation internet,
                ainsi qu’en particulier les conditions d’accès à ce Site le
                cas-échéant ou à d'autres sites ;
              </li>
              <li>
                que le paramétrage de son navigateur n'est pas définitif de
                telle sorte qu'il peut annuler tout ou partie des modifications
                qu'il aura apporté notamment en cas de difficulté de navigation
                ou d'accès à certaines fonctionnalités ou services, proposés par
                le Site ou tout autre site.
              </li>
            </ul>
            <p>
              Toute demande ou réclamation concernant la politique de
              confidentialité à effectuer via notre{" "}
              <AnchorLink to="/#contact">formulaire de contact</AnchorLink>.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Privacy;
